<template>
  <div class="wrapper bg-black flex flex-col items-center" v-if="leaderboard">
    <div class="flex justify-between px-4 mt-6">
      <div>
        <router-link :to="{ name: 'Catalog' }">
          <img :src="require('@/assets/images/logo-dark.webp')" class="w-2/6" />
        </router-link>
      </div>
      <div v-if="$route.name != 'Rewards'">
        <button
          @click.prevent="goToRewards()"
          class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-2 py-1 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Rewards
        </button>
      </div>
    </div>
    <div class="flex w-full justify-between pt-4 px-4 items-center">
      <p
        class="font-ZuumeSemiBold text-nColorYellow text-5xl text-shadow-black-right"
      >
        leaderboard
      </p>
      <div>
        <p
          class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
        >
          {{ formattedGamePlays }}
        </p>
        <p
          class="font-InterSemiBold text-nColorYellow text-sm text-shadow-black-right"
        >
          {{ formattedLeaderboardCount }}
        </p>
      </div>
    </div>
    <div v-if="leaderboard.leaderboard" class="w-full px-4 pt-2">
      <div
        v-for="(result, index) in leaderboard.leaderboard.results"
        :key="result"
      >
        <div
          :class="{
            'border border-nColorYellow': result.is_customer,
            'border border-black': !result.is_customer,
          }"
          class="flex justify-between items-center gap-2 text-white rounded-xl p-2"
          @click="toggleDetails(result, index + 1)"
          v-if="!showDetails || !result.is_customer"
        >
          <div class="w-10 text-center">
            <img
              :src="getPositionImage(index + 1)"
              :alt="'Position' + index + 1"
              width="32"
              height="36"
              v-if="index < 3"
            />
            <div
              v-else
              class="text-nColorGreen font-bold font-InterSemiBold text-xl"
            >
              <p v-if="!result.is_customer">{{ index + 1 }}</p>
              <p v-else>{{ leaderboard.leaderboard.customer_position }}</p>
            </div>
          </div>
          <div class="w-3/6">
            <p class="font-InterSemiBold text-lg">{{ result.player }}</p>
          </div>
          <div class="w-3/6">
            <div class="bg-lColorBGGray p-1.5 rounded-xl">
              <div class="flex gap-2">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                    alt="Score"
                    width="25"
                    height="24"
                  />
                </div>
                <p class="font-InterSemiBold">
                  {{ result.highest_score.score }}
                </p>
              </div>
              <div class="flex gap-2 mt-2" v-if="result.highest_score.time">
                <div class="pl-1">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                    alt="Time Taken"
                    width="20"
                    height="19"
                  />
                </div>
                <p class="font-InterSemiBold">
                  {{ timeTaken(result.highest_score.time) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="showDetails && result.is_customer"
          class="bg-black p-2 rounded-xl text-white border border-nColorYellow"
        >
          <div class="flex justify-between items-center gap-2 pt-0.5">
            <div class="w-10 text-center">
              <img
                :src="getPositionImage(this.customerPosition)"
                :alt="'Position' + this.customerPosition"
                width="32"
                height="36"
                v-if="index < 3"
              />
              <p
                v-else
                class="text-nColorGreen font-bold font-InterSemiBold text-xl"
              >
                {{ this.customerPosition }}
              </p>
            </div>
            <div class="w-4/6">
              <p class="font-InterSemiBold text-lg">
                {{ customerResult.player }}
              </p>
            </div>
            <div class="w-2/6">
              <button @click.stop="closeDetails()" class="float-right">
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/close.webp"
                  alt="Close"
                  width="30"
                  height="30"
                />
              </button>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 p-2">
            <div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl border-4 border-lColorBGGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  Current rank
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/rank.webp"
                      alt="Rank"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.leaderboard.customer_position }}
                  </p>
                </div>
              </div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl mt-2 border-4 border-lColorBGGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  Last Game
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                      alt="Rank"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.leaderboard.customer_results.last.score }}
                  </p>
                </div>
                <div
                  class="flex items-center gap-2 px-1"
                  v-if="leaderboard.leaderboard.customer_results.last.time"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                      alt="Time Taken"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{
                      timeTaken(
                        leaderboard.leaderboard.customer_results.last.time
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl border-4 border-lColorBGGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  No. of attempts
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/attempts.webp"
                      alt="Attempts"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.leaderboard.customer_results.attempts }}
                  </p>
                </div>
              </div>
              <div
                class="bg-lColorBGGray p-1.5 rounded-xl mt-2 border-4 border-nColorLabelGray"
              >
                <div class="font-bold font-InterSemiBold text-nColorYellow">
                  Best Game
                </div>
                <div
                  class="border-t-2 border-nColorLabelGray flex items-center gap-2 pt-2 px-1"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                      alt="Rank"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{ leaderboard.leaderboard.customer_results.highest.score }}
                  </p>
                </div>
                <div
                  class="flex items-center gap-2 px-1"
                  v-if="leaderboard.leaderboard.customer_results.highest.time"
                >
                  <div>
                    <img
                      src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                      alt="Time Taken"
                      width="20"
                      height="27"
                    />
                  </div>
                  <p class="font-InterSemiBold font-bold text-lg">
                    {{
                      timeTaken(
                        leaderboard.leaderboard.customer_results.highest.time
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed bottom-0 w-full px-8">
      <div class="w-full grid">
        <div
          class="shadow-border-orange-right bg-nColorYellow rounded-3xl w-full text-black text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none border border-black cursor-pointer"
        >
          <div
            class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
            @click.prevent="goToGame"
          >
            <p>Play again</p>
          </div>
        </div>
        <div class="mx-3 my-2 cursor-pointer">
          <div
            class="text-nColorYellow font-InterSemiBold text-xl flex justify-center items-center py-2 gap-2"
            @click.prevent="goBackToCatalog"
          >
            <p>Back To Catalog</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import slugs from "@/common/slugs";
import confetti from "canvas-confetti";

export default {
  components: {},
  data() {
    return {
      leaderboard: null,
      flipText: "Claim Freebie",
      unclaimed: [],
      gamePlays: 0,
      leaderboardCount: 0,
      showDetails: false,
      customerResult: null,
      customerPosition: null,
      slugs,
    };
  },
  created() {
    ApiService.get(apiResource.getLeaderboard, {
      business_slug: process.env.VUE_APP_PLAYDA_DEMO_SLUG,
      game: this.$route.params.game,
    }).then((data) => {
      this.leaderboard = data.data.data;
      this.gamePlays = this.leaderboard.total_gameplays;
      this.leaderboardCount = this.leaderboard.leaderboard.leaderboard_count;
    });
  },
  mounted() {
    setInterval(() => {
      this.flipText =
        this.flipText === "Click to Claim"
          ? "Congratulations!"
          : "Click to Claim";
    }, 1000);
  },
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    goBackToCatalog() {
      this.$router.push({
        name: "Catalog",
      });
    },
    goToRewards() {
      this.$router.push({
        name: "Rewards",
      });
    },
    reloadPage() {
      window.location.reload();
    },
    goToGame() {
      this.$router.push({
        name: "Game",
        params: {
          business: process.env.VUE_APP_PLAYDA_DEMO_SLUG,
          game: this.$route.params.game,
        },
      });
    },
    claimFreebie() {
      this.showConfetti();
      setTimeout(() => {
        this.$router.push({
          name: "Rewards",
          params: {
            business: process.env.VUE_APP_PLAYDA_DEMO_SLUG,
          },
        });
      }, 2000);
    },
    showConfetti() {
      // Configure the confetti options
      const confettiOptions = {
        particleCount: 1500,
        spread: 220,
        origin: { y: 0.7 },
      };

      // Trigger the confetti effect
      confetti(confettiOptions);

      // Stop the confetti after 1 second
      setTimeout(() => {
        confetti.reset();
      }, 2000);
    },
    reverseArray(array) {
      if (Array.isArray(array)) {
        return array.slice().reverse();
      } else {
        return array;
      }
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    formatFriendlyTime(timeString) {
      const [hour, minute] = timeString.split(":");
      let period = "AM";

      let hourNum = parseInt(hour, 10);
      if (hourNum >= 12) {
        period = "PM";
        if (hourNum > 12) {
          hourNum -= 12;
        }
      }

      return `${hourNum}:${minute} ${period}`;
    },
    showTrophy(position, game) {
      if (position <= parseInt(game.details.no_of_winners)) {
        return true;
      }
      return false;
    },
    toggleDetails(result, index) {
      if (result.is_customer) {
        this.customerResult = result;
        this.customerPosition = index;
        this.showDetails = true;
      }
    },
    closeDetails() {
      this.showDetails = false;
    },
    getPositionImage(index) {
      return `https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/position${index}.webp`;
    },
  },
  computed: {
    formattedGamePlays() {
      if (this.gamePlays >= 1000) {
        return (this.gamePlays / 1000).toFixed(2) + "k Game Plays";
      } else {
        return this.gamePlays + " Game Plays";
      }
    },
    formattedLeaderboardCount() {
      if (this.leaderboardCount >= 1000) {
        return (this.leaderboardCount / 1000).toFixed(2) + "k on Leaderboard";
      } else {
        return this.leaderboardCount + " on Leaderboard";
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.wrapper {
  height: 100dvh;
  overflow-y: auto;
  padding-bottom: 100px;
}
@keyframes sparkle {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.sparkle-button {
  display: inline-block;
  animation: sparkle 0.75s infinite;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0deg);
  }
  50% {
    transform: perspective(400px) rotateY(90deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
  }
}

#flip-text {
  animation: flip 2s infinite;
}
.normal-row {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  padding: 8px;
  text-align: left;
}
.highlighted-row {
  @apply bg-nColorYellow;
  padding: 8px;
}
.highlighted-row td {
  padding: 8px;
}
.you-text {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  color: black;
}
</style>
